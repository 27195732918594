<template>
  <modal @close="$emit('close')">
    <div class="text-2xl">
      แสดงคำขอบคุณ หรือให้กำลังใจ  
      <p class="text-banyen">"{{ userNickName }}"</p>
      ผ่านการเลี้ยงเครื่องดื่ม
    </div>
    <div class="grid grid-cols-3 gap-4 mt-8 justify-items-center">
      <div
        v-for="option in tipOptions"
        class="relative"
      >
        <div
          :key="option.id"
          class="relative border-4 border-black rounded-xl flex flex-col items-center w-28 mx-1 cursor-pointer h-full"
          @click="selectedOption = option.id"
        >
          <div class="flex items-center text-banyen text-sm mt-2">
            + {{ option.amount }}ติ๊บ
            <img
              src="/tip/tip-icon.png"
              class="w-5 h-5 ml-1"
            >
          </div>
          <img
            :src="option.image"
            class="relative mb-2"
            :class="(option.amount === 100 ? 'w-16' : 'w-12') + ' ' + ((option.id === 'cocktail-4' || option.id === 'cocktail-5') && 'top-2')" 
          >
          <div
            v-show="option.isMostPopular"
            class="text-banyen absolute bottom-2"
          >
            Most Popular
          </div>
          <div
            class="absolute bottom-[-15px] text-lg rounded-3xl border-black border-2 px-3"
            :class="selectedOption === option.id ? 'bg-banyen' : 'bg-cream-blue'"
          >
            <div v-if="option.id === 'cocktail-0'" class="flex items-center">
              ฟรี
              <img 
                src="/boost/boost-logo.png"
                class="w-5 h-5 ml-1"
              />
            </div>
            <div v-else>
              {{ option.amount }} บาท
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-xl mt-16">
      *เครื่องดื่มจะกลายเป็นติ๊บให้
    </div>
    <div class="text-xl">
      บาร์เทนเดอร์ไว้ใช้สอยในเว็บบาร์ทิพย์
    </div>
    <div class="text-xl">
      และเป็นการสนับสนุนแพลตฟอร์มด้วยน้า
    </div>
    <div class="flex justify-center mt-8">
      <button
        class="bg-cream-blue text-lg border-black border-2 rounded-3xl px-6 py-1 mr-2"
        @click="$emit('close')"
      >
        ผ่านก่อน
      </button>
      <button
        class="bg-banyen text-lg border-black border-2 rounded-3xl px-6 py-1"
        @click="onTipClicked"
      >
        เลี้ยง!
      </button>
    </div>
  </modal>
</template>

<script>
import BanyenButton from '../common/BanyenButton.vue'
import global from '~/mixins/global.js'
import Modal from '~/components/common/Modal.vue'

export default {
  components: {
    Modal,
    BanyenButton,
  },
  mixins: [global],
  props: {
    userId: {
      type: String,
      required: true,
    },
    userNickName: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.checkFreeTip()
  },
  data: () => ({
    tipOptions: [
      {
        id: 'cocktail-0',
        image: '/tip/cocktail-0.png',
        amount: 1,
      },
      {
        id: 'cocktail-1',
        image: '/tip/cocktail-1.png',
        amount: 10,
      },
      {
        id: 'cocktail-2',
        image: '/tip/cocktail-2.png',
        isMostPopular: true,
        amount: 50,
      },
      {
        id: 'cocktail-3',
        image: '/tip/cocktail-3.png',
        amount: 100,
      },
      {
        id: 'cocktail-4',
        image: '/tip/cocktail-4.png',
        amount: 300,
      },
      {
        id: 'cocktail-5',
        image: '/tip/cocktail-5.png',
        amount: 500,
      },
    ],
    selectedOption: null,
    freeTip: false,
  }),
  methods: {
    async checkFreeTip() {
      const { freeTip } = await $fetch('/api/free-tip', {
        method: 'GET',
        baseURL: this.$config.public.appBaseURL,
        headers: this.headers,
      })
      this.freeTip = freeTip
    },
    async onTipClicked() {
      if (!this.selectedOption) {
        this.$notify({
          type: 'error',
          text: 'กรุณาเลือกแก้วที่ต้องการเลี้ยงก่อน',
        })
        return
      }

      if (this.selectedOption === 'cocktail-0' && !this.isBoosted) {
        this.$notify({
          type: 'info',
          text: 'คุณต้องบูสก่อน ถึงจะได้สิทธิ์เลี้ยงเครื่องดื่มฟรี',
        })
        return
      }
      try {
        this.$store.setPageLoading(true)
        const data = await $fetch('/api/payments/checkout', {
          method: 'POST',
          baseURL: this.$config.public.appBaseURL,
          body: { productCode: this.selectedOption, toUserId: this.userId },
          headers: this.headers,
        })
        if (data.freeTip) {
          this.$notify({
            type: 'success',
            text: 'ทำการเลี้ยงเครื่องดื่มสำเร็จ',
          })
          this.$emit('close')
        } else {
          window.location = data.url
        }
      }
      catch (error) {
        if (error.data?.message === 'free-tip-not-available') {
          this.$notify({
            type: 'error',
            text: 'วันนี้คุณได้ใช้สิทธ์เลี้ยงเครื่องดื่มฟรีแล้ว',
          })
        } else {
          this.$notify({
            type: 'error',
            text: 'เกิดข้อผิดพลาดขณะซื้อ Cocktail ให้เพื่อนกรุณาลองใหม่อีกครั้งค่ะ',
          })
        }
      }
      this.$store.setPageLoading(false)
    },
  },
}
</script>
