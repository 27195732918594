<template>
  <modal @close="$emit('close')">
    <div class="max-w-xl w-full text-center">
      <div class="flex items-center justify-center gap-1">
        <p class="text-2xl font-semibold">
          รีพอร์ต
        </p>
        <img
          src="/report/report-flag.png"
          class="h-9"
        >
      </div>
      <div v-if="error === 'daily-report-limit-reached'">
        <div class="my-5 text-report-purple">
          <p>คุณได้ใช้สิทธิ์ในการรีพอร์ตของวันนี้หมดแล้ว</p>
          <p>(สามารถเพิ่มสิทธิ์ผ่านการเป็นสมาชิกบูสต์ได้)</p>
        </div>
        <div class="my-5">
          <p>ยูสเซอร์ที่ล็อกอินมีสิทธิ์รีพอร์ต 1 ครั้ง/วัน</p>
          <p>ยูสเซอร์บูสต์มีสิทธิ์รีพอร์ต 3 ครั้ง/วัน</p>
        </div>
        <NuxtLink
          class="flex justify-center"
          to="/boost"
        >
          <dom-button
            class="py-3 px-5 w-48 font-semibold gap-1 text-xl"
            @click.native="$emit('close')"
          >
            <p>ซื้อบูสต์</p>
            <img
              src="/boost/boost-logo.png"
              class="w-8"
            >
          </dom-button>
        </NuxtLink>
      </div>
      <div v-else-if="error === 'duplicate-report'">
        <div class="my-5 text-report-purple">
          <p>คุณได้ทำการรีพอร์ต</p>
          <p class="text-xl font-semibold">
            "{{ memberToReport.nickName }}"
          </p>
        </div>
        <div class="my-5">
          <p>ว่าทำตัว<span class="underline">ไม่เป็นพื้นที่ปลอดภัย</span>ในเว็ปไซต์นี้ไปแล้ว</p>
        </div>
        <p class="my-5">
          ไม่สามารถทำการรีพอร์ตยูเซอร์เดิมซ้ำได้ใน 3 วัน
        </p>
        <div class="flex justify-center">
          <dom-button
            class="py-3 px-5 w-48 font-semibold text-xl"
            @click.native="$emit('close')"
          >
            โอเค
          </dom-button>
        </div>
      </div>
      <div v-else>
        <div class="my-5 text-report-purple">
          <p>คุณกำลังรีพอร์ต</p>
          <p class="text-xl font-semibold">
            "{{ memberToReport.nickName }}"
          </p>
        </div>
        <div class="my-5 font-semibold">
          <p>ว่าทำตัว<span class="underline">ไม่เป็นพื้นที่ปลอดภัย</span>ในเว็ปไซต์นี้</p>
        </div>
        <div class="my-5">
          <p>เมื่อยูสเซอร์ถูกรีพอร์ตจากยูสเซอร์จำนวนหนึ่ง</p>
          <p>ระบบจะทำการแบนตามหลักการข้างล่างนี้</p>
        </div>
        <div class="my-5">
          <p>โดนแบนครั้งที่ 1 : 3 วัน</p>
          <p>โดนแบนครั้งที่ 2 : 7 วัน</p>
          <p>โดนแบนครั้งที่ 3 : 1 เดือน</p>
          <p>โดนแบนครั้งที่ 4 : ตลอดไป</p>
        </div>
        <div class="my-5">
          การรีพอร์ตนี้ส่งผลกระทบต่อยูสเซอร์คนอื่น ขอให้ใช้วิจารณญาณในการรีพอร์ต
        </div>
        <div class="text-report-purple my-5">
          {{ getReportLimit() }}
        </div>
        <div class="flex justify-center">
          <dom-button
            class="py-3 px-5 w-48 font-semibold text-xl"
            @click.native="confirmReport"
          >
            รีพอร์ต
          </dom-button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import global from '~/mixins/global'
import DomButton from '~/components/DomButton.vue'
import Modal from '~/components/common/Modal.vue'
import dayjs from 'dayjs'

export default {
  components: {
    DomButton,
    Modal,
  },
  mixins: [global],
  props: {
    memberToReport: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      error: null,
    }
  },
  methods: {
    getReportLimit() {
      if (this.isBoosted) {
        return 'คุณมีสิทธิ์ในการรีพอร์ตเพียง 3 ครั้งต่อวันเท่านั้น'
      }

      if (dayjs.utc(this.user.insertedAt).isAfter(dayjs.utc().subtract(1, 'month'))) {
        return 'ในเดือนแรกของการใช้งาน คุณไม่มีสิทธิ์ในการรีพอร์ต'
      }

      return 'คุณมีสิทธิ์ในการรีพอร์ตเพียง 1 ครั้งในทุกๆ 3 วัน'
    },
    async confirmReport() {
      try {
        this.$store.setPageLoading(true)
        await $fetch('/api/reports', {
          method: 'POST',
          baseURL: this.$config.public.appBaseURL,
          body: { reportedUserId: this.memberToReport.userId },
          headers: this.headers,
        })

        this.$notify({
          type: 'success',
          text: 'ส่งรีพอร์ตเรียบร้อย ขอบคุณที่ร่วมกันทำให้เว็บไซต์เป็นพื้นที่ปลอดภัย',
        })

        this.$emit('close')
      }
      catch (error) {
        switch (error.data?.message) {
          case 'user-new-cannot-report':
            this.$notify({
              type: 'info',
              text: 'ในเดือนแรกของการใช้งาน คุณไม่มีสิทธิ์ในการรีพอร์ต',
            })
            break
          case 'report-limit-reached':
            this.$notify({
              type: 'info',
              text: 'คุณได้ใช้สิทธิ์ในการรีพอร์ตหมดแล้ว',
            })
            break
          case 'bar-closed':
            this.$notify({
              type: 'info',
              text: 'Bar is currently closed. We close from 04:00 - 04:15 GMT+7 everyday. Please come later :)',
            })
            break
          case 'daily-report-limit-reached':
            this.error = 'daily-report-limit-reached'
            break
          case 'duplicate-report':
            this.error = 'duplicate-report'
            break
          case 'user-is-currently-banned':
            this.$notify({
              type: 'info',
              text: 'ยูสเซอร์นี้ถูกแบนอยู่แล้วในขณะนี้ เก็บไว้รีพอร์ตใหม่ในภายหลังนะคะ',
            })

            this.$emit('close')
            break
          default:
            this.$notify({
              type: 'error',
              text: 'เกิดปัญหาระหว่างการส่งรีพอร์ต กรุณาลองอีกครั้ง',
            })
        }
      }
      this.$store.setPageLoading(false)
    },
  },
}
</script>
